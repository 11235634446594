export const artistImgDir = "/img/artists/";

export const artistData = [
  {
    img: "kitchen-chaos_1500x1125.jpg",
    mediumImg: "kitchen-chaos_800x600.jpg",
    thumb: "kitchen-chaos_250x188.jpg",
    label: "Kitchen Chaos",
    name: "Messi Maker",
    // links: [
    //   {
    //     label: "Instagram",
    //     url: "https://www.instagram.com/_adartist_81/",
    //   },
    //   {
    //     label: "DeviantArt",
    //     url: "https://www.deviantart.com/adartist81",
    //   },
    // ],
  },
  {
    img: "AlexandraVranceanu-1.jpg",
    mediumImg: "AlexandraVranceanu-1_800x566.jpg",
    thumb: "AlexandraVranceanu-1_250x177.jpg",
    label: "Digital",
    name: "Alexandra Vranceanu",
    links: [
      {
        label: "Instagram",
        url: "https://www.instagram.com/_adartist_81/",
      },
      {
        label: "DeviantArt",
        url: "https://www.deviantart.com/adartist81",
      },
    ],
  },
  {
    img: "jennie-inkpen_1500x1061.jpg",
    mediumImg: "jennie-inkpen_800x566.jpg",
    thumb: "jennie-inkpen_250x177.jpg",
    label: "Fineliner",
    name: "Jennie Dennett",
    links: [
      {
        label: "Ulverston Candlelit Walk",
        url: "https://www.facebook.com/candlelitwalk",
      },
    ],
  },
  {
    img: "jennie-paint_1500x1075.jpg",
    mediumImg: "jennie-paint_800x573.jpg",
    thumb: "jennie-paint_250x179.jpg",
    label: "Acrylic",
    name: "Jennie Dennett",
    links: [
      {
        label: "Ulverston Candlelit Walk",
        url: "https://www.facebook.com/candlelitwalk",
      },
    ],
  },
];
