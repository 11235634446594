import React from "react";

export const TizzieLogo = ({ height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 720 720"
      height={height}
    >
      <path
        fill="#fff"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="26.598"
        d="M-409.034 271.21c107.5 66.692 192.066 199.532 157.25 252.108-25.266 34.969-58.982-39.911-225.447-82.365-166.464-42.455-166.127-152.498-119.9-199.88 46.225-47.383 98.657-25.35 188.097 30.138z"
        color="#000"
        overflow="visible"
        paintOrder="markers fill stroke"
        transform="matrix(.64512 .38582 -.32347 .68298 786.44 141.023)"
      ></path>
      <path d="M561.089 208.039c-21.131 9.079-35.793 59.201-35.793 59.201-20.73 1.7-35.155-56.642-34.015-60.062-11.733 22.124-31.956 81.857-31.956 81.857-60.663-44.29-121.587-15.25-121.587-15.25 35.628 23.552 22.715 58.411 22.715 58.411-64.832-32.045-111.084 5.377-111.084 5.377 33.606 13.051 75.317 30.486 43.792 30.012-13.87-.208-71.827-3.371-99.88 20.163 43.246 15.495 43.453 34.914 43.453 34.914-52.305-17.925-77.669 42.073-77.669 42.073 28.567 14.213 56.14 1.64 46.561 20.188-11.885-2.897-41.107 38.324-43.621 59.674 28.71-21.695 41.028-2.406 56.443 9.138-38.113 14.015-46.425 37.489-46.425 37.489 19.57-4.127 61.392 18.144 61.392 18.144-1.863 4.26-20.397 40.88-20.397 40.88l38.306-5.026c30.563-9.615 44.138-10.057 58.252-16.98 6.5 10.62 11.756 13.862 4.483 31.368 11.894 5.126 16.484 10.87 48.797-1.02-1.323-25.012-10.255-44.452-9.63-67.613 104.197-16.324 166.269-95.47 166.269-95.47 10.688 12.999 15.221 45.417 25.537 52.528 6.402 4.098 16.477 4.991 23.922 4.267 6.635-.645 14.118-3.378 18.62-8.557-.274.315-8.733-13.047-9.299-14.21-6.473-12.447-9.236-44.472-3.747-54.436 10.518-16.305 25.976-19.86 47.028-24.652 15.858-2.555 30.585-12.703 37.87-25.453 8.069-7.21 21.707-21.506 11.248-32.671-13.093-13.977-36.786-24.538-44.673-27.186-26.199-8.8-40.819-29.076-55.094-78.465-6.367-23.833-2.787-59.961-9.818-78.633z"></path>
      <g
        fillOpacity="0.919"
        color="#000"
        paintOrder="markers fill stroke"
        transform="translate(107.612 154.92)"
      >
        <circle
          cx="440.948"
          cy="219.162"
          r="38.058"
          fill="#fff"
          overflow="visible"
        ></circle>
        <circle
          cx="440.948"
          cy="219.162"
          r="15.748"
          overflow="visible"
        ></circle>
      </g>
      <path
        fillOpacity="0.919"
        d="M175.298 341.793C69.059 625.39 197.796 651.651 261.7 591.736c38.94-36.508-3.447 58.32-10.377 53.486C130.05 720.231 22.504 495.847 67.685 310.297c27.126-111.4 22.736-162.225 78.74-156.128 60.274 6.562 65.067 91.004 28.873 187.624z"
        color="#000"
        overflow="visible"
        paintOrder="markers fill stroke"
      ></path>
      <path
        fill="#fff"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="25.375"
        d="M-405.732 283.066c102.548 63.73 183.27 190.581 150.105 240.738-24.07 33.358-56.268-38.136-215.026-78.776-158.76-40.64-158.507-145.67-114.459-190.863 44.049-45.191 94.06-24.124 179.38 28.9z"
        color="#000"
        overflow="visible"
        paintOrder="markers fill stroke"
        transform="matrix(.76733 .18116 -.1074 .78427 714.912 26.695)"
      ></path>
    </svg>
  );
};
